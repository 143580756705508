<script>

	import { onMount, onDestroy, getContext } from 'svelte';
	import fadeScale from 'svelte-transitions-fade-scale';
	import { fade } from "svelte/transition";
	import { flip } from 'svelte/animate';
	import { quintOut } from 'svelte/easing';

	import cssVars from 'svelte-css-vars';

	import Participant from "./Stage/Participant.svelte";
	import Background from "./Stage/Background.svelte";
	import Clip from "./Stage/Clip.svelte";
	import Overlay from "./Stage/Overlay.svelte";
	import Banner from "./Stage/Banner.svelte";

	import { event } from "../../lib/stores.js";

	const participants = getContext('participants');
	const layout = getContext('layout');
	const flipDuration = getContext('flipDuration');

	const background = getContext('background');
	const overlay = getContext('overlay');
	const clip = getContext('clip');
	const banner = getContext('banner');
	const highlight = getContext('highlight');
	const host = getContext('host');

	export let sw = 0;

	let sh = 0;
	let bannerHeight = 0;
	let bannerPercentage;

	let hasSpotlight = false;
	
	let sorted = [];

	let contentKey = null;

	let nameTagStyles = {};

	function sortParticipants() {
		// console.log('(stage) sortParticipants');
		let s = [];
		let screenShare = false;
		let spot = false;
		for (const p of $participants) {
			if (p.o) {
				if (!screenShare && p.id.startsWith('s_') && (($layout == 'side') || ($layout == 'side2') || ($layout == 'cinema') || ($layout == 'inset'))) {
					screenShare = true;
					s.unshift(p);
				} else {
					s.push(p);
				}
				if (p.s) spot = true;
			}
		}
		hasSpotlight = spot;
		sorted = s;
	}

	function setContentKey() {
		// console.log('setContentKey', $highlight, $banner);
		if ($highlight) {
			contentKey = $highlight.r;
		} else if ($banner) {
			contentKey = $banner;
		} else {
			contentKey = null;
		}
	}

	function setNameTagColors() {
		if ($event.setup.virtual && $event.setup.virtual.studio && $event.setup.virtual.studio.nameTags) {
			if (($event.setup.virtual.studio.nameTags.style == 'banner') && $event.setup.virtual.studio.banners) {
				nameTagStyles.nameTagFont = $event.setup.virtual.studio.banners.font;
				nameTagStyles.nameTagTextColor = $event.theme.colors[$event.setup.virtual.studio.banners.textColor];
				nameTagStyles.nameTagBgColor = $event.theme.colors[$event.setup.virtual.studio.banners.bgColor];
				nameTagStyles.nameTagBgOpacity = parseInt($event.setup.virtual.studio.banners.bgOpacity) / 100;
			} else {
				nameTagStyles.nameTagFont = $event.setup.virtual.studio.nameTags.font;
				nameTagStyles.nameTagTextColor = $event.theme.colors[$event.setup.virtual.studio.nameTags.textColor];
				nameTagStyles.nameTagBgColor = $event.theme.colors[$event.setup.virtual.studio.nameTags.bgColor];
				nameTagStyles.nameTagBgOpacity = parseInt($event.setup.virtual.studio.nameTags.bgOpacity) / 100;
			}
		} else {
			nameTagStyles.nameTagFont = 'Inter';
			nameTagStyles.nameTagTextColor = 'var(--textColor)';
			nameTagStyles.nameTagBgColor = 'var(--panelColor)';
			nameTagStyles.nameTagBgOpacity = 0.9;
		}
	}

	$: if ($event) {
		setNameTagColors();
	}

	$: if ($participants || $layout) {
		sortParticipants();
	}

	$: bannerPercentage = (bannerHeight / sh) * 100;

	$: setContentKey($banner, $highlight);

</script>

<style>
	.outer {
		flex: 0 1 auto;
		position: relative;
		aspect-ratio: 16/9;
		width: 100%;
		max-height: calc(100% - 6rem);
	}
	.outer.host {
		max-height: calc(100% - 12rem);
	}
	.stage {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #000;
		aspect-ratio: 16/9;
		border-radius: 4px;
		box-shadow: 0 2px 10px var(--shadow);
		max-width: 100%;
		max-height: 100%;
		height: 100%;
    	overflow: hidden;
	}

	.layout {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}

	.layout.grid:not(.stretch):not(.hasSpotlight) {
		transition: transform 0.3s ease, height 0.3s ease;
	}

	.layout.grid:not(.stretch):not(.p1):not(.p2):not(.p3).hasBanner:not(.hasSpotlight) {
/*		transform: scale(calc(85% - (1% * var(--bp)))) translateY(calc(0px - var(--bh)));*/
		transform: scale(calc(0.85 - (0.01 * var(--bp)))) translateY(calc(0px - var(--bh)));
		transform-origin: 50% 50%;
	}

	/*.layout.grid:not(.stretch):not(.p1):not(.p2):not(.p3).hasBanner:not(.hasSpotlight) :global(.control button) {
		transform: scale(calc(115% + (1% * var(--bp))));
		transform-origin: 50% 50%;
	}*/

	.layout.grid:not(.stretch):not(.p1):not(.p2):not(.p3).hasBanner:not(.hasSpotlight) :global(.actions) {
/*		transform: scale(calc(115% + (1% * var(--bp))));*/
		transform: scale(calc(1.15 + (0.01 * var(--bp))));
		transform-origin: 100% 0%;
	}

	.layout.grid:not(.stretch):not(.p1):not(.p2):not(.p3).hasBanner:not(.hasSpotlight) :global(.nameTag) {
/*		transform: scale(calc(115% + (1% * var(--bp))));*/
		transform: scale(calc(1.15 + (0.01 * var(--bp))));
		transform-origin: 0% 100%;
	}

	.layout.grid:not(.stretch).p2.hasBanner:not(.hasSpotlight),
	.layout.grid:not(.stretch).p3.hasBanner:not(.hasSpotlight) {
		height: calc(100% - var(--bh));
	}

	.layout.gap {
		top: calc(var(--sw) * 0.008);
		left: calc(var(--sw) * 0.008);
		width: calc(100% - ((var(--sw) * 0.008) * 2));
		height: calc(100% - ((var(--sw) * 0.008) * 2));
	}

	.layout .slot {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		transition: all 0.3s ease;
		box-sizing: border-box;
		padding: 0;
	}

	.layout .slot.spotlight {
		top: 0 !important;
		left: 0 !important;
		width: 100% !important;
		height: 100% !important;
		padding: 0 !important;
/*		z-index: 100;*/
/*		transition-delay: 300;*/
	}

	.layout.hasSpotlight .slot:not(.spotlight) {
		opacity: 0 !important;
	}

	.layout.gap .slot.spotlight {
		top: calc(0px - (var(--sw) * 0.008)) !important;
		left: calc(0px - (var(--sw) * 0.008)) !important;
		width: calc(100% + ((var(--sw) * 0.008) * 2)) !important;
		height: calc(100% + ((var(--sw) * 0.008) * 2)) !important;
	}

	.layout.side .slot.spotlight,
	.layout.cinema .slot.spotlight {
		top: calc(0px - (var(--sw) * 0.016)) !important;
		left: calc(0px - (var(--sw) * 0.016)) !important;
		width: calc(100% + ((var(--sw) * 0.016) * 2)) !important;
		height: calc(100% + ((var(--sw) * 0.016) * 2)) !important;
	}

	.layout.gap .slot {
		padding: calc(var(--sw) * 0.01);
	}

	.layout :global(.video) {
		flex: 0 1 auto;
		background: rgba(255,255,255,0.1);
		aspect-ratio: 16/9;
		min-height: 0;
		min-width: 87.91441%;
/*		transition: min-height 0.2s ease, min-width 0.2s ease;*/
		transition: all 0.2s ease;
		position: relative;
		overflow: hidden;
	}

	.layout.grid.gap :global(.video),
	.layout.side :global(.video),
	.layout.cinema :global(.video),
	.layout.inset :global(.video) {
		box-shadow: 0 0 calc(var(--sw) * 0.01) rgba(0, 0, 0, 0.3);
	}

	.layout.gap :global(.video) {
		border-radius: calc(var(--sw) * 0.005);
	}

	.layout.grid.p0 .slot {
		width: 0%;
		transition: 0s;
	}


	.layout.grid.p1 .slot:nth-of-type(1) {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.layout.grid.p2 .slot:nth-of-type(1) {
		top: 0;
		left: 0;
		width: 50%;
		height: 100%;
	}
	.layout.grid.p2 .slot:nth-of-type(2) {
		top: 0%;
		left: 50%;
		width: 50%;
		height: 100%;
	}

	.layout.grid.p3 .slot:nth-of-type(1) {
		top: 0;
		left: 0;
		width: calc(100% / 3);
		height: 100%;
	}
	.layout.grid.p3 .slot:nth-of-type(2) {
		top: 0%;
		left: calc(100% / 3);
		width: calc(100% / 3);
		height: 100%;
	}
	.layout.grid.p3 .slot:nth-of-type(3) {
		top: 0%;
		left: calc((100% / 3) * 2);
		width: calc(100% / 3);
		height: 100%;
	}

	.layout.grid.p4 .slot:nth-of-type(1) {
		top: 0;
		left: 0;
		width: calc(100% / 2);
		height: calc(100% / 2);
	}
	.layout.grid.p4 .slot:nth-of-type(2) {
		top: 0%;
		left: calc(100% / 2);
		width: calc(100% / 2);
		height: calc(100% / 2);
	}
	.layout.grid.p4 .slot:nth-of-type(3) {
		top: calc(100% / 2);
		left: 0;
		width: calc(100% / 2);
		height: calc(100% / 2);
	}
	.layout.grid.p4 .slot:nth-of-type(4) {
		top: calc(100% / 2);
		left: calc(100% / 2);
		width: calc(100% / 2);
		height: calc(100% / 2);
	}

	.layout.grid.p5 .slot:nth-of-type(1) {
		top: 0;
		left: 0;
		width: calc(100% / 3);
		height: calc(100% / 2);
		align-items: flex-end;
	}
	.layout.grid.p5 .slot:nth-of-type(2) {
		top: 0;
		left: calc(100% / 3);
		width: calc(100% / 3);
		height: calc(100% / 2);
		align-items: flex-end;
	}
	.layout.grid.p5 .slot:nth-of-type(3) {
		top: 0;
		left: calc((100% / 3) * 2);
		width: calc(100% / 3);
		height: calc(100% / 2);
		align-items: flex-end;
	}
	.layout.grid.p5 .slot:nth-of-type(4) {
		top: calc(100% / 2);
		left: calc((100% / 3) * 0.5);
		width: calc(100% / 3);
		height: calc(100% / 2);
		align-items: flex-start;
	}
	.layout.grid.p5 .slot:nth-of-type(5) {
		top: calc(100% / 2);
		left: calc((100% / 3) * 1.5);
		width: calc(100% / 3);
		height: calc(100% / 2);
		align-items: flex-start;
	}

	.layout.grid.p6 .slot:nth-of-type(1) {
		top: 0;
		left: 0;
		width: calc(100% / 3);
		height: calc(100% / 2);
		align-items: flex-end;
	}
	.layout.grid.p6 .slot:nth-of-type(2) {
		top: 0;
		left: calc(100% / 3);
		width: calc(100% / 3);
		height: calc(100% / 2);
		align-items: flex-end;
	}
	.layout.grid.p6 .slot:nth-of-type(3) {
		top: 0;
		left: calc((100% / 3) * 2);
		width: calc(100% / 3);
		height: calc(100% / 2);
		align-items: flex-end;
	}
	.layout.grid.p6 .slot:nth-of-type(4) {
		top: calc(100% / 2);
		left: 0;
		width: calc(100% / 3);
		height: calc(100% / 2);
		align-items: flex-start;
	}
	.layout.grid.p6 .slot:nth-of-type(5) {
		top: calc(100% / 2);
		left: calc(100% / 3);
		width: calc(100% / 3);
		height: calc(100% / 2);
		align-items: flex-start;
	}
	.layout.grid.p6 .slot:nth-of-type(6) {
		top: calc(100% / 2);
		left: calc((100% / 3) * 2);
		width: calc(100% / 3);
		height: calc(100% / 2);
		align-items: flex-start;
	}

	.layout.grid.p7 .slot:nth-of-type(1) {
		top: 0;
		left: 0;
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p7 .slot:nth-of-type(2) {
		top: 0;
		left: calc(100% / 3);
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p7 .slot:nth-of-type(3) {
		top: 0;
		left: calc((100% / 3) * 2);
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p7 .slot:nth-of-type(4) {
		top: calc(100% / 3);
		left: 0;
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p7 .slot:nth-of-type(5) {
		top: calc(100% / 3);
		left: calc(100% / 3);
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p7 .slot:nth-of-type(6) {
		top: calc(100% / 3);
		left: calc((100% / 3) * 2);
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p7 .slot:nth-of-type(7) {
		top: calc((100% / 3) * 2);
		left: calc((100% / 3) * 1);
		width: calc(100% / 3);
		height: calc(100% / 3);
	}

	.layout.grid.p8 .slot:nth-of-type(1) {
		top: 0;
		left: 0;
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p8 .slot:nth-of-type(2) {
		top: 0;
		left: calc(100% / 3);
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p8 .slot:nth-of-type(3) {
		top: 0;
		left: calc((100% / 3) * 2);
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p8 .slot:nth-of-type(4) {
		top: calc(100% / 3);
		left: 0;
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p8 .slot:nth-of-type(5) {
		top: calc(100% / 3);
		left: calc(100% / 3);
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p8 .slot:nth-of-type(6) {
		top: calc(100% / 3);
		left: calc((100% / 3) * 2);
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p8 .slot:nth-of-type(7) {
		top: calc((100% / 3) * 2);
		left: calc((100% / 3) * 0.5);
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p8 .slot:nth-of-type(8) {
		top: calc((100% / 3) * 2);
		left: calc((100% / 3) * 1.5);
		width: calc(100% / 3);
		height: calc(100% / 3);
	}

	.layout.grid.p9 .slot:nth-of-type(1) {
		top: 0;
		left: 0;
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p9 .slot:nth-of-type(2) {
		top: 0;
		left: calc(100% / 3);
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p9 .slot:nth-of-type(3) {
		top: 0;
		left: calc((100% / 3) * 2);
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p9 .slot:nth-of-type(4) {
		top: calc(100% / 3);
		left: 0;
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p9 .slot:nth-of-type(5) {
		top: calc(100% / 3);
		left: calc(100% / 3);
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p9 .slot:nth-of-type(6) {
		top: calc(100% / 3);
		left: calc((100% / 3) * 2);
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p9 .slot:nth-of-type(7) {
		top: calc((100% / 3) * 2);
		left: 0;
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p9 .slot:nth-of-type(8) {
		top: calc((100% / 3) * 2);
		left: calc((100% / 3) * 1);
		width: calc(100% / 3);
		height: calc(100% / 3);
	}
	.layout.grid.p9 .slot:nth-of-type(9) {
		top: calc((100% / 3) * 2);
		left: calc((100% / 3) * 2);
		width: calc(100% / 3);
		height: calc(100% / 3);
	}

	.layout.grid.p10 .slot:nth-of-type(1) {
		top: calc((100% / 4) * 0.5);
		left: 0;
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p10 .slot:nth-of-type(2) {
		top: calc((100% / 4) * 0.5);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p10 .slot:nth-of-type(3) {
		top: calc((100% / 4) * 0.5);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p10 .slot:nth-of-type(4) {
		top: calc((100% / 4) * 0.5);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p10 .slot:nth-of-type(5) {
		top: calc((100% / 4) * 1.5);
		left: 0;
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p10 .slot:nth-of-type(6) {
		top: calc((100% / 4) * 1.5);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p10 .slot:nth-of-type(7) {
		top: calc((100% / 4) * 1.5);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p10 .slot:nth-of-type(8) {
		top: calc((100% / 4) * 1.5);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p10 .slot:nth-of-type(9) {
		top: calc((100% / 4) * 2.5);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p10 .slot:nth-of-type(10) {
		top: calc((100% / 4) * 2.5);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}

	.layout.grid.p11 .slot:nth-of-type(1) {
		top: calc((100% / 4) * 0.5);
		left: 0;
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p11 .slot:nth-of-type(2) {
		top: calc((100% / 4) * 0.5);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p11 .slot:nth-of-type(3) {
		top: calc((100% / 4) * 0.5);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p11 .slot:nth-of-type(4) {
		top: calc((100% / 4) * 0.5);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p11 .slot:nth-of-type(5) {
		top: calc((100% / 4) * 1.5);
		left: 0;
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p11 .slot:nth-of-type(6) {
		top: calc((100% / 4) * 1.5);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p11 .slot:nth-of-type(7) {
		top: calc((100% / 4) * 1.5);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p11 .slot:nth-of-type(8) {
		top: calc((100% / 4) * 1.5);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p11 .slot:nth-of-type(9) {
		top: calc((100% / 4) * 2.5);
		left: calc((100% / 4) * 0.5);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p11 .slot:nth-of-type(10) {
		top: calc((100% / 4) * 2.5);
		left: calc((100% / 4) * 1.5);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p11 .slot:nth-of-type(11) {
		top: calc((100% / 4) * 2.5);
		left: calc((100% / 4) * 2.5);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}

	.layout.grid.p12 .slot:nth-of-type(1) {
		top: calc((100% / 4) * 0.5);
		left: 0;
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p12 .slot:nth-of-type(2) {
		top: calc((100% / 4) * 0.5);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p12 .slot:nth-of-type(3) {
		top: calc((100% / 4) * 0.5);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p12 .slot:nth-of-type(4) {
		top: calc((100% / 4) * 0.5);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p12 .slot:nth-of-type(5) {
		top: calc((100% / 4) * 1.5);
		left: 0;
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p12 .slot:nth-of-type(6) {
		top: calc((100% / 4) * 1.5);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p12 .slot:nth-of-type(7) {
		top: calc((100% / 4) * 1.5);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p12 .slot:nth-of-type(8) {
		top: calc((100% / 4) * 1.5);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p12 .slot:nth-of-type(9) {
		top: calc((100% / 4) * 2.5);
		left: calc((100% / 4) * 0);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p12 .slot:nth-of-type(10) {
		top: calc((100% / 4) * 2.5);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p12 .slot:nth-of-type(11) {
		top: calc((100% / 4) * 2.5);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p12 .slot:nth-of-type(12) {
		top: calc((100% / 4) * 2.5);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}

	.layout.grid.gap.p10 .slot,
	.layout.grid.gap.p11 .slot,
	.layout.grid.gap.p12 .slot {
		height: calc((100% / 4) + (var(--sw) * 0.01));
	}

	.layout.grid.gap.p10 .slot:nth-of-type(1),
	.layout.grid.gap.p10 .slot:nth-of-type(2),
	.layout.grid.gap.p10 .slot:nth-of-type(3),
	.layout.grid.gap.p10 .slot:nth-of-type(4),
	.layout.grid.gap.p11 .slot:nth-of-type(1),
	.layout.grid.gap.p11 .slot:nth-of-type(2),
	.layout.grid.gap.p11 .slot:nth-of-type(3),
	.layout.grid.gap.p11 .slot:nth-of-type(4),
	.layout.grid.gap.p12 .slot:nth-of-type(1),
	.layout.grid.gap.p12 .slot:nth-of-type(2),
	.layout.grid.gap.p12 .slot:nth-of-type(3),
	.layout.grid.gap.p12 .slot:nth-of-type(4) {
		top: calc(((100% / 4) * 0.5) - (var(--sw) * 0.005));
	}

	.layout.grid.gap.p10 .slot:nth-of-type(9),
	.layout.grid.gap.p10 .slot:nth-of-type(10),
	.layout.grid.gap.p11 .slot:nth-of-type(9),
	.layout.grid.gap.p11 .slot:nth-of-type(10),
	.layout.grid.gap.p11 .slot:nth-of-type(11),
	.layout.grid.gap.p12 .slot:nth-of-type(9),
	.layout.grid.gap.p12 .slot:nth-of-type(10),
	.layout.grid.gap.p12 .slot:nth-of-type(11),
	.layout.grid.gap.p12 .slot:nth-of-type(12) {
		top: calc(((100% / 4) * 2.5) + (var(--sw) * 0.005));
	}

	.layout.grid.stretch.p10 .slot,
	.layout.grid.stretch.p11 .slot,
	.layout.grid.stretch.p12 .slot {
		height: calc(100% / 3);
	}

	.layout.grid.stretch.p10 .slot:nth-of-type(1),
	.layout.grid.stretch.p10 .slot:nth-of-type(2),
	.layout.grid.stretch.p10 .slot:nth-of-type(3),
	.layout.grid.stretch.p10 .slot:nth-of-type(4),
	.layout.grid.stretch.p11 .slot:nth-of-type(1),
	.layout.grid.stretch.p11 .slot:nth-of-type(2),
	.layout.grid.stretch.p11 .slot:nth-of-type(3),
	.layout.grid.stretch.p11 .slot:nth-of-type(4),
	.layout.grid.stretch.p12 .slot:nth-of-type(1),
	.layout.grid.stretch.p12 .slot:nth-of-type(2),
	.layout.grid.stretch.p12 .slot:nth-of-type(3),
	.layout.grid.stretch.p12 .slot:nth-of-type(4) {
		top: 0;
	}

	.layout.grid.stretch.p10 .slot:nth-of-type(5),
	.layout.grid.stretch.p10 .slot:nth-of-type(6),
	.layout.grid.stretch.p10 .slot:nth-of-type(7),
	.layout.grid.stretch.p10 .slot:nth-of-type(8),
	.layout.grid.stretch.p11 .slot:nth-of-type(5),
	.layout.grid.stretch.p11 .slot:nth-of-type(6),
	.layout.grid.stretch.p11 .slot:nth-of-type(7),
	.layout.grid.stretch.p11 .slot:nth-of-type(8),
	.layout.grid.stretch.p12 .slot:nth-of-type(5),
	.layout.grid.stretch.p12 .slot:nth-of-type(6),
	.layout.grid.stretch.p12 .slot:nth-of-type(7),
	.layout.grid.stretch.p12 .slot:nth-of-type(8) {
		top: calc(100% / 3);
	}

	.layout.grid.stretch.p10 .slot:nth-of-type(9),
	.layout.grid.stretch.p10 .slot:nth-of-type(10),
	.layout.grid.stretch.p11 .slot:nth-of-type(9),
	.layout.grid.stretch.p11 .slot:nth-of-type(10),
	.layout.grid.stretch.p11 .slot:nth-of-type(11),
	.layout.grid.stretch.p12 .slot:nth-of-type(9),
	.layout.grid.stretch.p12 .slot:nth-of-type(10),
	.layout.grid.stretch.p12 .slot:nth-of-type(11),
	.layout.grid.stretch.p12 .slot:nth-of-type(12) {
		top: calc((100% / 3) * 2);
	}

	.layout.grid.p13 .slot:nth-of-type(1) {
		top: calc((100% / 4) * 0);
		left: 0;
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p13 .slot:nth-of-type(2) {
		top: calc((100% / 4) * 0);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p13 .slot:nth-of-type(3) {
		top: calc((100% / 4) * 0);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p13 .slot:nth-of-type(4) {
		top: calc((100% / 4) * 0);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p13 .slot:nth-of-type(5) {
		top: calc((100% / 4) * 1);
		left: 0;
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p13 .slot:nth-of-type(6) {
		top: calc((100% / 4) * 1);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p13 .slot:nth-of-type(7) {
		top: calc((100% / 4) * 1);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p13 .slot:nth-of-type(8) {
		top: calc((100% / 4) * 1);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p13 .slot:nth-of-type(9) {
		top: calc((100% / 4) * 2);
		left: calc((100% / 4) * 0);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p13 .slot:nth-of-type(10) {
		top: calc((100% / 4) * 2);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p13 .slot:nth-of-type(11) {
		top: calc((100% / 4) * 2);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p13 .slot:nth-of-type(12) {
		top: calc((100% / 4) * 2);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p13 .slot:nth-of-type(13) {
		top: calc((100% / 4) * 3);
		left: calc((100% / 4) * 1.5);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}

	.layout.grid.p14 .slot:nth-of-type(1) {
		top: calc((100% / 4) * 0);
		left: 0;
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p14 .slot:nth-of-type(2) {
		top: calc((100% / 4) * 0);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p14 .slot:nth-of-type(3) {
		top: calc((100% / 4) * 0);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p14 .slot:nth-of-type(4) {
		top: calc((100% / 4) * 0);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p14 .slot:nth-of-type(5) {
		top: calc((100% / 4) * 1);
		left: 0;
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p14 .slot:nth-of-type(6) {
		top: calc((100% / 4) * 1);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p14 .slot:nth-of-type(7) {
		top: calc((100% / 4) * 1);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p14 .slot:nth-of-type(8) {
		top: calc((100% / 4) * 1);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p14 .slot:nth-of-type(9) {
		top: calc((100% / 4) * 2);
		left: calc((100% / 4) * 0);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p14 .slot:nth-of-type(10) {
		top: calc((100% / 4) * 2);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p14 .slot:nth-of-type(11) {
		top: calc((100% / 4) * 2);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p14 .slot:nth-of-type(12) {
		top: calc((100% / 4) * 2);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p14 .slot:nth-of-type(13) {
		top: calc((100% / 4) * 3);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p14 .slot:nth-of-type(14) {
		top: calc((100% / 4) * 3);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}

	.layout.grid.p15 .slot:nth-of-type(1) {
		top: calc((100% / 4) * 0);
		left: 0;
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p15 .slot:nth-of-type(2) {
		top: calc((100% / 4) * 0);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p15 .slot:nth-of-type(3) {
		top: calc((100% / 4) * 0);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p15 .slot:nth-of-type(4) {
		top: calc((100% / 4) * 0);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p15 .slot:nth-of-type(5) {
		top: calc((100% / 4) * 1);
		left: 0;
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p15 .slot:nth-of-type(6) {
		top: calc((100% / 4) * 1);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p15 .slot:nth-of-type(7) {
		top: calc((100% / 4) * 1);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p15 .slot:nth-of-type(8) {
		top: calc((100% / 4) * 1);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p15 .slot:nth-of-type(9) {
		top: calc((100% / 4) * 2);
		left: calc((100% / 4) * 0);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p15 .slot:nth-of-type(10) {
		top: calc((100% / 4) * 2);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p15 .slot:nth-of-type(11) {
		top: calc((100% / 4) * 2);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p15 .slot:nth-of-type(12) {
		top: calc((100% / 4) * 2);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p15 .slot:nth-of-type(13) {
		top: calc((100% / 4) * 3);
		left: calc((100% / 4) * 0.5);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p15 .slot:nth-of-type(14) {
		top: calc((100% / 4) * 3);
		left: calc((100% / 4) * 1.5);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p15 .slot:nth-of-type(15) {
		top: calc((100% / 4) * 3);
		left: calc((100% / 4) * 2.5);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}

	.layout.grid.p16 .slot:nth-of-type(1) {
		top: calc((100% / 4) * 0);
		left: 0;
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p16 .slot:nth-of-type(2) {
		top: calc((100% / 4) * 0);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p16 .slot:nth-of-type(3) {
		top: calc((100% / 4) * 0);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p16 .slot:nth-of-type(4) {
		top: calc((100% / 4) * 0);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p16 .slot:nth-of-type(5) {
		top: calc((100% / 4) * 1);
		left: 0;
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p16 .slot:nth-of-type(6) {
		top: calc((100% / 4) * 1);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p16 .slot:nth-of-type(7) {
		top: calc((100% / 4) * 1);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p16 .slot:nth-of-type(8) {
		top: calc((100% / 4) * 1);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p16 .slot:nth-of-type(9) {
		top: calc((100% / 4) * 2);
		left: calc((100% / 4) * 0);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p16 .slot:nth-of-type(10) {
		top: calc((100% / 4) * 2);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p16 .slot:nth-of-type(11) {
		top: calc((100% / 4) * 2);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p16 .slot:nth-of-type(12) {
		top: calc((100% / 4) * 2);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p16 .slot:nth-of-type(13) {
		top: calc((100% / 4) * 3);
		left: calc((100% / 4) * 0);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p16 .slot:nth-of-type(14) {
		top: calc((100% / 4) * 3);
		left: calc((100% / 4) * 1);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p16 .slot:nth-of-type(15) {
		top: calc((100% / 4) * 3);
		left: calc((100% / 4) * 2);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}
	.layout.grid.p16 .slot:nth-of-type(16) {
		top: calc((100% / 4) * 3);
		left: calc((100% / 4) * 3);
		width: calc(100% / 4);
		height: calc(100% / 4);
	}

	.layout.grid.p1 :global(.video),
	.layout.grid.p4 :global(.video),
	.layout.grid.p7 :global(.video),
	.layout.grid.p8 :global(.video),
	.layout.grid.p9 :global(.video),
	.layout.grid.p13 :global(.video),
	.layout.grid.p14 :global(.video),
	.layout.grid.p15 :global(.video),
	.layout.grid.p16 :global(.video) {
		width: auto;
		height: 100%;
	}

	.layout.grid.p2 :global(.video),
	.layout.grid.p3 :global(.video),
	.layout.grid.p5 :global(.video),
	.layout.grid.p6 :global(.video),
	.layout.grid.p10 :global(.video),
	.layout.grid.p11 :global(.video),
	.layout.grid.p12 :global(.video) {
		width: 100%;
		max-height: 100%;
	}

	.layout.stretch :global(.video) {
		min-height: 100%;
		min-width: 100%;
	}

	.layout.stretch:not(.hasSpotlight) {
		transition: height 0.3s ease;
	}

	.layout.stretch.hasBanner:not(.hasSpotlight) {
		height: calc(100% - var(--bh) - ((var(--sw) * 0.016) * 3.4));
	}


	.layout.side {
		top: calc(var(--sw) * 0.016);
		left: calc(var(--sw) * 0.016);
		width: calc(100% - ((var(--sw) * 0.016) * 2));
		height: calc(100% - ((var(--sw) * 0.016) * 2));
	}

	.layout.side:not(.hasSpotlight) {
		transition: transform 0.3s ease;
	}

	.layout.side .slot {
		position: absolute;
		top: 0;
		left: 0;
		width: calc((100% / 3) - (var(--sw) * 0.016));
	}

	.layout.side.right .slot {
		left: calc(100% - ((100% / 3) - (var(--sw) * 0.016)));
	}

	.layout.side.p1 .slot:nth-of-type(1) {
		left: 0;
		width: 100%;
		height: 100%;
	}

	.layout.side:not(.p1) .slot:nth-of-type(1) {
		top: 0;
		left: calc(100% / 3);
		width: calc((100% / 3) * 2);
		height: 100%;
	}

	.layout.side.right:not(.p1) .slot:nth-of-type(1) {
		left: 0;
	}

	.layout.side.p2 .slot:nth-of-type(2) {
		top: calc((100% / 3) * 1);
	}

	.layout.side.p3 .slot:nth-of-type(2) {
		top: calc(((100% / 3) * 0.5) - (var(--sw) * 0.005));
	}
	.layout.side.p3 .slot:nth-of-type(3) {
		top: calc(((100% / 3) * 1.5) + (var(--sw) * 0.008));
	}

	.layout.side.p4 .slot:nth-of-type(1) {
		left: calc((100% / 3) - ((var(--sw) * 0.008)));
		width: calc((100% / 3) * 2);
	}
	.layout.side.right.p4 .slot:nth-of-type(1) {
		left: calc(100% - ((100% / 3) * 2) - ((100% / 3) - ((var(--sw) * 0.008))));
	}
	.layout.side.p4 .slot:nth-of-type(2) {
		width: calc((100% / 3) - ((var(--sw) * 0.016) * 2));
		left: calc((var(--sw) * 0.016) * 0.5);
		top: calc(((100% / 3) * 0) + (var(--sw) * 0.005));
	}
	.layout.side.p4 .slot:nth-of-type(3) {
		width: calc((100% / 3) - ((var(--sw) * 0.016) * 2));
		left: calc((var(--sw) * 0.016) * 0.5);
		top: calc(((100% / 3) * 1) + (var(--sw) * 0.005));
	}
	.layout.side.p4 .slot:nth-of-type(4) {
		width: calc((100% / 3) - ((var(--sw) * 0.016) * 2));
		left: calc((var(--sw) * 0.016) * 0.5);
		top: calc(((100% / 3) * 2) + (var(--sw) * 0.005));
	}
	.layout.side.right.p4 .slot:nth-of-type(2),
	.layout.side.right.p4 .slot:nth-of-type(3),
	.layout.side.right.p4 .slot:nth-of-type(4) {
		left: calc(100% - ((100% / 3) - ((var(--sw) * 0.016) * 2)) - ((var(--sw) * 0.016) * 0.5));
	}

	.layout.side.p5 .slot:nth-of-type(1) {
		left: calc((100% / 4) - ((var(--sw) * 0.008)));
		width: calc(100% - (100% / 4));
	}

	.layout.side.right.p5 .slot:nth-of-type(1) {
		left: calc(100% - ((100% / 4) - ((var(--sw) * 0.008))) - (100% - (100% / 4)));
	}
	.layout.side.p5 .slot:nth-of-type(2) {
		width: calc((100% / 4) - ((var(--sw) * 0.016) * 2));
		left: calc((var(--sw) * 0.016) * 0.5);
		top: calc(((100% / 4) * 0) + (var(--sw) * 0.005));
	}
	.layout.side.p5 .slot:nth-of-type(3) {
		width: calc((100% / 4) - ((var(--sw) * 0.016) * 2));
		left: calc((var(--sw) * 0.016) * 0.5);
		top: calc(((100% / 4) * 1) + (var(--sw) * 0.005));
	}
	.layout.side.p5 .slot:nth-of-type(4) {
		width: calc((100% / 4) - ((var(--sw) * 0.016) * 2));
		left: calc((var(--sw) * 0.016) * 0.5);
		top: calc(((100% / 4) * 2) + (var(--sw) * 0.005));
	}
	.layout.side.p5 .slot:nth-of-type(5) {
		width: calc((100% / 4) - ((var(--sw) * 0.016) * 2));
		left: calc((var(--sw) * 0.016) * 0.5);
		top: calc(((100% / 4) * 3) + (var(--sw) * 0.005));
	}
	.layout.side.right.p5 .slot:nth-of-type(2),
	.layout.side.right.p5 .slot:nth-of-type(3),
	.layout.side.right.p5 .slot:nth-of-type(4),
	.layout.side.right.p5 .slot:nth-of-type(5) {
		left: calc(100% - ((100% / 4) - ((var(--sw) * 0.016) * 2)) - ((var(--sw) * 0.016) * 0.5));
	}

	.layout.side.p6 .slot:nth-of-type(1) {
		left: calc((100% / 5) - ((var(--sw) * 0.008)));
		width: calc(100% - (100% / 5));
	}
	.layout.side.right.p6 .slot:nth-of-type(1) {
		left: calc(100% - ((100% / 5) - ((var(--sw) * 0.008))) - (100% - (100% / 5)));
	}
	.layout.side.p6 .slot:nth-of-type(2) {
		width: calc((100% / 5) - ((var(--sw) * 0.016) * 2));
		left: calc((var(--sw) * 0.016) * 0.5);
		top: calc(((100% / 5) * 0) + (var(--sw) * 0.005));
	}
	.layout.side.p6 .slot:nth-of-type(3) {
		width: calc((100% / 5) - ((var(--sw) * 0.016) * 2));
		left: calc((var(--sw) * 0.016) * 0.5);
		top: calc(((100% / 5) * 1) + (var(--sw) * 0.005));
	}
	.layout.side.p6 .slot:nth-of-type(4) {
		width: calc((100% / 5) - ((var(--sw) * 0.016) * 2));
		left: calc((var(--sw) * 0.016) * 0.5);
		top: calc(((100% / 5) * 2) + (var(--sw) * 0.005));
	}
	.layout.side.p6 .slot:nth-of-type(5) {
		width: calc((100% / 5) - ((var(--sw) * 0.016) * 2));
		left: calc((var(--sw) * 0.016) * 0.5);
		top: calc(((100% / 5) * 3) + (var(--sw) * 0.005));
	}
	.layout.side.p6 .slot:nth-of-type(6) {
		width: calc((100% / 5) - ((var(--sw) * 0.016) * 2));
		left: calc((var(--sw) * 0.016) * 0.5);
		top: calc(((100% / 5) * 4) + (var(--sw) * 0.005));
	}
	.layout.side.right.p6 .slot:nth-of-type(2),
	.layout.side.right.p6 .slot:nth-of-type(3),
	.layout.side.right.p6 .slot:nth-of-type(4),
	.layout.side.right.p6 .slot:nth-of-type(5),
	.layout.side.right.p6 .slot:nth-of-type(6) {
		left: calc(100% - ((100% / 5) - ((var(--sw) * 0.016) * 2)) - ((var(--sw) * 0.016) * 0.5));
	}

	.layout.side :global(.video) {
		border-radius: calc(var(--sw) * 0.005);
		width: 100%;
		max-height: 100%;
	}
	.layout.side.p1 :global(.video) {
		width: auto;
		height: 100%;
	}

	.layout.side.hasBanner:not(.hasSpotlight) {
/*		transform: scale(calc(90% - (1% * var(--bp)))) translateY(calc(0px - var(--bh) + (var(--sw) * 0.016)));*/
		transform: scale(calc(0.9 - (0.01 * var(--bp)))) translateY(calc(0px - var(--bh) + (var(--sw) * 0.016)));
		transform-origin: 50% 50%;
	}

	/*.layout.side.hasBanner:not(.hasSpotlight) :global(.control button) {
		transform: scale(calc(110% + (1% * var(--bp))));
		transform-origin: 50% 50%;
	}*/

	.layout.side.hasBanner:not(.hasSpotlight) :global(.actions) {
/*		transform: scale(calc(110% + (1% * var(--bp))));*/
		transform: scale(calc(1.1 + (0.01 * var(--bp))));
		transform-origin: 100% 0%;
	}

	.layout.side.hasBanner:not(.hasSpotlight) :global(.nameTag) {
/*		transform: scale(calc(110% + (1% * var(--bp))));*/
		transform: scale(calc(1.1 + (0.01 * var(--bp))));
		transform-origin: 0% 100%;
	}

	.layout.side.hasBanner:not(.hasSpotlight).p1 {
/*		transform: scale(calc(88% - (1% * var(--bp)))) translateY(calc(0px - var(--bh)));*/
		transform: scale(calc(0.88 - (0.01 * var(--bp)))) translateY(calc(0px - var(--bh)));
	}

	/*.layout.side.hasBanner:not(.hasSpotlight).p1 :global(.control button) {
		transform: scale(calc(112% + (1% * var(--bp))));
		transform-origin: 50% 50%;
	}*/

	.layout.side.hasBanner:not(.hasSpotlight).p1 :global(.actions) {
/*		transform: scale(calc(112% + (1% * var(--bp))));*/
		transform: scale(calc(1.12 + (0.01 * var(--bp))));
		transform-origin: 100% 0%;
	}

	.layout.side.hasBanner:not(.hasSpotlight).p1 :global(.nameTag) {
/*		transform: scale(calc(112% + (1% * var(--bp))));*/
		transform: scale(calc(1.12 + (0.01 * var(--bp))));
		transform-origin: 0% 100%;
	}

	.layout.side.hasBanner:not(.hasSpotlight).p2,
	.layout.side.hasBanner:not(.hasSpotlight).p3 {
/*		transform: scale(calc(110% - (1% * var(--bp)))) translateY(calc(0px - var(--bh) + (var(--sw) * 0.032)));*/
		transform: scale(calc(1.1 - (0.01 * var(--bp)))) translateY(calc(0px - var(--bh) + (var(--sw) * 0.032)));
	}

	/*.layout.side.hasBanner:not(.hasSpotlight).p2 :global(.control button),
	.layout.side.hasBanner:not(.hasSpotlight).p3 :global(.control button) {
		transform: scale(calc(90% + (1% * var(--bp))));
		transform-origin: 50% 50%;
	}*/

	.layout.side.hasBanner:not(.hasSpotlight).p2 :global(.actions),
	.layout.side.hasBanner:not(.hasSpotlight).p3 :global(.actions) {
/*		transform: scale(calc(90% + (1% * var(--bp))));*/
		transform: scale(calc(0.9 + (0.01 * var(--bp))));
		transform-origin: 100% 0%;
	}

	.layout.side.hasBanner:not(.hasSpotlight).p2 :global(.nameTag),
	.layout.side.hasBanner:not(.hasSpotlight).p3 :global(.nameTag) {
/*		transform: scale(calc(90% + (1% * var(--bp))));*/
		transform: scale(calc(0.9 + (0.01 * var(--bp))));
		transform-origin: 0% 100%;
	}


	.layout.cinema {
		top: calc(var(--sw) * 0.016);
		left: calc(var(--sw) * 0.016);
		width: calc(100% - ((var(--sw) * 0.016) * 2));
		height: calc(100% - ((var(--sw) * 0.016) * 2));
		transition: height 0.3s ease;
	}

	.layout.cinema .slot {
		position: absolute;
/*		bottom: 0;*/
		top: calc(100% - (((var(--sw) / 4) - (var(--sw) * 0.032)) * 0.5625));
		left: 0;
		width: calc((100% / 4) - (var(--sw) * 0.032));
		aspect-ratio: 16/9;
	}

	.layout.cinema.p1 .slot:nth-of-type(1) {
		top: 0;
		width: 100%;
		height: 100%;
	}

	.layout.cinema:not(.p1) .slot:nth-of-type(1) {
/*		bottom: calc((100% / 4));*/
		top: 0;
		left: 0;
		height: calc(100% - (100% / 4));
		width: 100%;
	}

	.layout.cinema.p2 .slot:nth-of-type(2) {
		left: calc(50% - (((100% / 4) - (var(--sw) * 0.032)) / 2));
	}

	.layout.cinema.p3 .slot:nth-of-type(2) {
		left: calc((50% - ((100% / 4) - (var(--sw) * 0.032))) - ((var(--sw) * 0.016) / 2));
	}
	.layout.cinema.p3 .slot:nth-of-type(3) {
		left: calc(50% + ((var(--sw) * 0.016) / 2));
	}

	.layout.cinema.p4 .slot:nth-of-type(2) {
		left: calc(50% - (((100% / 4) - (var(--sw) * 0.032)) / 2) - ((100% / 4) - (var(--sw) * 0.032)) - (var(--sw) * 0.016));
	}
	.layout.cinema.p4 .slot:nth-of-type(3) {
		left: calc(50% - (((100% / 4) - (var(--sw) * 0.032)) / 2));
	}
	.layout.cinema.p4 .slot:nth-of-type(4) {
		left: calc(50% + (((100% / 4) - (var(--sw) * 0.032)) / 2) + (var(--sw) * 0.016));
	}

	.layout.cinema.p5 .slot:nth-of-type(2) {
		left: calc((50% - (((100% / 4) - (var(--sw) * 0.032))) * 2) - (((var(--sw) * 0.016) / 2) * 3));
	}
	.layout.cinema.p5 .slot:nth-of-type(3) {
		left: calc((50% - ((100% / 4) - (var(--sw) * 0.032))) - ((var(--sw) * 0.016) / 2));
	}
	.layout.cinema.p5 .slot:nth-of-type(4) {
		left: calc(50% + ((var(--sw) * 0.016) / 2));
	}
	.layout.cinema.p5 .slot:nth-of-type(5) {
		left: calc(50% + ((100% / 4) - (var(--sw) * 0.032)) + (((var(--sw) * 0.016) / 2) * 3));
	}

	.layout.cinema.p6 .slot {
		width: calc((100% / 5) - (var(--sw) * 0.032));
		top: calc(100% - (((var(--sw) / 5) - (var(--sw) * 0.032)) * 0.5625));
	}

	.layout.cinema.p6 .slot:nth-of-type(1) {
		height: calc(100% - (100% / 5));
/*		bottom: calc((100% / 5));*/
	}

	.layout.cinema.p6 .slot:nth-of-type(2) {
		left: calc(50% - (((100% / 5) - (var(--sw) * 0.032)) / 2) - ((100% / 5) - (var(--sw) * 0.032)) - (var(--sw) * 0.016) - ((100% / 5) - (var(--sw) * 0.032)) - (var(--sw) * 0.016));
	}
	.layout.cinema.p6 .slot:nth-of-type(3) {
		left: calc(50% - (((100% / 5) - (var(--sw) * 0.032)) / 2) - ((100% / 5) - (var(--sw) * 0.032)) - (var(--sw) * 0.016));
	}
	.layout.cinema.p6 .slot:nth-of-type(4) {
		left: calc(50% - (((100% / 5) - (var(--sw) * 0.032)) / 2));
	}
	.layout.cinema.p6 .slot:nth-of-type(5) {
		left: calc(50% + (((100% / 5) - (var(--sw) * 0.032)) / 2) + (var(--sw) * 0.016));
	}
	.layout.cinema.p6 .slot:nth-of-type(6) {
		left: calc(50% + (((100% / 5) - (var(--sw) * 0.032))) + (var(--sw) * 0.016) + (((100% / 5) - (var(--sw) * 0.032)) / 2) + (var(--sw) * 0.016));
	}

	.layout.cinema :global(.video) {
		border-radius: calc(var(--sw) * 0.005);
		width: 100%;
		max-height: 100%;
	}

	.layout.cinema:nth-of-type(1) :global(.video) {
		width: auto;
		height: 100%;
		min-width: 0;
	}

	.layout.cinema.hasBanner:not(.hasSpotlight) {
		height: calc(100% - var(--bh) - ((var(--sw) * 0.016) * 4.4));
	}


	.layout.inset {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.layout.inset .slot {
		position: absolute;
/*		bottom: calc((var(--sw) * 0.016) * 2);*/
		top: calc(100% - (((var(--sw) / 4) - (var(--sw) * 0.032)) * 0.5625) - ((var(--sw) * 0.016) * 2));
		left: 0;
		width: calc((100% / 4) - (var(--sw) * 0.032));
		aspect-ratio: 16/9;
/*		transition: bottom 0.3s ease;*/
		z-index: 2;
	}

	.layout.inset .slot:nth-of-type(1) {
/*		bottom: 0;*/
		bottom: auto;
		top: 0;
		width: 100%;
		height: 100%;
/*		transition: none;*/
		z-index: 1;
	}

	.layout.inset.hasSpotlight .slot:nth-of-type(1) {
/*		z-index: 3;*/
	}

	.layout.inset .slot:nth-of-type(1).spotlight {
		transition: none;		
	}

	.layout.inset .slot:nth-of-type(2) {
		left: calc((var(--sw) * 0.016) * 2);
	}
	.layout.inset .slot:nth-of-type(3) {
		left: calc(((var(--sw) * 0.016) * 2) + (((100% / 4) - (var(--sw) * 0.032)) + (var(--sw) * 0.016)));
	}
	.layout.inset .slot:nth-of-type(4) {
		left: calc(((var(--sw) * 0.016) * 2) + (((100% / 4) - ((var(--sw) * 0.032)) + (var(--sw) * 0.016)) * 2));
	}

	.layout.inset.p5 .slot:not(:nth-of-type(1)) {
		width: calc((100% / 5) - (var(--sw) * 0.032));
		top: calc(100% - (((var(--sw) / 5) - (var(--sw) * 0.032)) * 0.5625) - ((var(--sw) * 0.016) * 2));
	}
	.layout.inset.p5 .slot:nth-of-type(3) {
		left: calc(((var(--sw) * 0.016) * 2) + (((100% / 5) - (var(--sw) * 0.032)) + (var(--sw) * 0.016)));
	}
	.layout.inset.p5 .slot:nth-of-type(4) {
		left: calc(((var(--sw) * 0.016) * 2) + (((100% / 5) - ((var(--sw) * 0.032)) + (var(--sw) * 0.016)) * 2));
	}
	.layout.inset.p5 .slot:nth-of-type(5) {
		left: calc(((var(--sw) * 0.016) * 2) + (((100% / 5) - ((var(--sw) * 0.032)) + (var(--sw) * 0.016)) * 3));
	}

	.layout.inset.p6 .slot:not(:nth-of-type(1)) {
		width: calc((100% / 6) - (var(--sw) * 0.032));
		top: calc(100% - (((var(--sw) / 6) - (var(--sw) * 0.032)) * 0.5625) - ((var(--sw) * 0.016) * 2));
	}
	.layout.inset.p6 .slot:nth-of-type(3) {
		left: calc(((var(--sw) * 0.016) * 2) + (((100% / 6) - (var(--sw) * 0.032)) + (var(--sw) * 0.016)));
	}
	.layout.inset.p6 .slot:nth-of-type(4) {
		left: calc(((var(--sw) * 0.016) * 2) + (((100% / 6) - ((var(--sw) * 0.032)) + (var(--sw) * 0.016)) * 2));
	}
	.layout.inset.p6 .slot:nth-of-type(5) {
		left: calc(((var(--sw) * 0.016) * 2) + (((100% / 6) - ((var(--sw) * 0.032)) + (var(--sw) * 0.016)) * 3));
	}
	.layout.inset.p6 .slot:nth-of-type(6) {
		left: calc(((var(--sw) * 0.016) * 2) + (((100% / 6) - ((var(--sw) * 0.032)) + (var(--sw) * 0.016)) * 4));
	}

	.layout.inset :global(.video) {
		border-radius: calc(var(--sw) * 0.005);
/*		width: 100%;*/
		max-width: 100%;
		max-height: 100%;
		box-shadow: 0 calc(var(--sw) * 0.004) calc(var(--sw) * 0.016) var(--shadow);
	}

	.layout.inset:nth-of-type(1) :global(.video) {
		/*width: 100%;
		height: 100%;*/
		min-width: 100%;
		min-height: 100%;
		transition-duration: 0s;
	}

	.layout.inset.hasBanner:not(.hasSpotlight) .slot:nth-of-type(2),
	.layout.inset.hasBanner:not(.hasSpotlight) .slot:nth-of-type(3),
	.layout.inset.hasBanner:not(.hasSpotlight) .slot:nth-of-type(4),
	.layout.inset.hasBanner:not(.hasSpotlight) .slot:nth-of-type(5),
	.layout.inset.hasBanner:not(.hasSpotlight) .slot:nth-of-type(6) {
/*		bottom: calc(((var(--sw) * 0.016) * 3.4) + var(--bh));*/
		top: calc(100% - (((var(--sw) / 4) - (var(--sw) * 0.032)) * 0.5625) - ((var(--sw) * 0.016) * 3.4) - var(--bh));
		transition-delay: 0.07s;
	}

</style>

<svelte:head>
	{#if $event && $event.setup && $event.setup.virtual && $event.setup.virtual.studio && (($event.setup.virtual.studio.banners && $event.setup.virtual.studio.banners.font && ($event.setup.virtual.studio.banners.font != 'Inter')) || ($event.setup.virtual.studio.nameTags && ($event.setup.virtual.studio.nameTags.display != 'hide') && ($event.setup.virtual.studio.nameTags.style != 'banner') && $event.setup.virtual.studio.nameTags.font && ($event.setup.virtual.studio.nameTags.font != 'Inter')))}
		<link rel="stylesheet" href={$event.theme.fonts.import}/>
	{/if}
</svelte:head>

<div class="outer" class:host>
	<div class="stage" bind:clientWidth={sw} bind:clientHeight={sh} style="--sw:{sw}px; --sh:{sh}px; --bh:{bannerHeight}px; --bp:{bannerPercentage}">
		{#key $background}
			<Background/>
		{/key}
		<div
			class="layout p{sorted.length}"
			class:grid={($layout == 'grid') || ($layout == 'gap') || ($layout == 'stretch')}
			class:gap={($layout == 'gap') || ($layout == 'stretch')}
			class:stretch={$layout == 'stretch'}
			class:side={($layout == 'side') || ($layout == 'side2')}
			class:right={$layout == 'side2'}
			class:cinema={$layout == 'cinema'}
			class:inset={$layout == 'inset'}
			class:hasBanner={contentKey != null}
			class:hasSpotlight
			use:cssVars={nameTagStyles}
		>
			{#each sorted as p, i (p.id)}
				<div
					class="slot"
					class:spotlight={p.s}
					in:fadeScale|local={{ duration: 300, baseScale: 0.9 }}
					animate:flip={{ duration: $flipDuration }}
				>
					<Participant participant={p} {i}/>
				</div>
			{/each}
		</div>
		{#if $clip}
			{#key $clip.r}
				<Clip/>
			{/key}
		{/if}
		{#key $overlay}
			<Overlay/>
		{/key}
		{#key contentKey}
			<Banner bind:bannerHeight {contentKey}/>
		{/key}
	</div>
</div>