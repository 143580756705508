<script>

	import ProgressBar from "../../../ui/ProgressBar.svelte";

	export let poll;
	export let results;
	export let studioBanner = false;

	let totalVotes = 0;
	let percentages = {};
	let winners = {};

	function calcVotes() {
		let total = 0;
		Object.values(results).forEach(v => {
			total += v;
		});
		totalVotes = total;
	}

	function calcPercentages() {
		let p = {};
		Object.values(poll.options).forEach(opt => {
			p[opt.value] = getPercentage(results[opt.value]);
		});
		percentages = p;
	}

	function getPercentage(votes) {
		if (!votes) return 0;
		return Number(((votes / totalVotes) * 100).toFixed(1));
	}

	function calcWinners() {
		let maxValue = 0;
		const values = Object.keys(percentages);
		values.map(p => {
			maxValue = Math.max(maxValue, percentages[p]);
		});
		Object.keys(percentages).forEach(p => {
			if (percentages[p] == maxValue) {
				winners[p] = true;
			}
		});
		// console.log({maxValue},{winners});
	}

	$: if (results) {
		calcVotes();
		calcPercentages();
		calcWinners();
	}

</script>

<style>
	.question {
		font-size: 0.8rem;
		margin-bottom: 0.75rem;
		font-weight: var(--bannerFontWeight, 700);
	}
	.banner .question {
		font-size: 1em;
		margin-bottom: 0.5em;
	}
	.option {
		margin: 0.5rem 0;
		border: 1px solid var(--blend-20);
		padding: 0.5rem;
		border-radius: 4px;
		background: var(--panelColor);
	}
	.banner .options {
		display: flex;
		gap: 0.5em;
		margin: 0;
	}
	.banner .option {
		position: relative;
		flex: 1 1 calc(100% / var(--c));
		margin: 0;
		padding: 0.25em 0.4em;
		display: flex;
		flex-direction: column;
		border: 0;
/*		box-shadow: 0 0 calc(var(--sw) * 0.01) calc(0px - (var(--sw) * 0.006)) var(--shadow);*/
		background: transparent;
		color: var(--bannerTextColor);
	}
	.banner .option.winner {
		color: var(--bannerBgColor);
	}
	.banner .option:before {
		content: '';
		position: absolute;
		inset: 0;
		border-radius: calc(var(--sw) * 0.003);
		background: var(--bannerTextColor);
		opacity: 0.15;
		z-index: 0;
	}
	.banner .option.winner:before {
		opacity: 1;
	}
	/*.banner .option :global(.progressOuter) {
		margin-top: auto;
	}*/
	.option p {
		margin: 0;
		font-size: 0.6875rem;
	}
	.banner .option p {
		font-size: 1em;
	}
	.option .label {
		display: flex;
		justify-content: space-between;
		gap: 1em;
		color: var(--textColor);
	}
	.banner .option .label {
		flex-direction: column;
		gap: 0.3em;
/*		margin-bottom: 0.6875em;*/
		flex: 1 0 auto;
		color: inherit;
		position: relative;
		z-index: 2;
	}
	.banner .labeltext {
		font-size: 0.8em;
		font-weight: 400;
	}
	.banner .percentage {
		font-size: 2em;
		line-height: 1;
	}
	.banner .percentage span {
		font-size: 0.6em;
	}
	.votes {
		color: var(--blend-60);
	}
</style>

<div class:banner={studioBanner} style="--c:{poll.options.length}">
	<p class="question">{poll.question}</p>
	<div class="options">
		{#each poll.options as opt, i (opt.value)}
			<div class="option" class:winner={winners[opt.value]}>
				<p class="label">
					<strong class="labeltext">{opt.label}</strong>
					<strong class="percentage">{percentages[opt.value]}<span>%</span></strong>
				</p>
				{#if !studioBanner}
					<ProgressBar amount={results[opt.value]} total={totalVotes} delay={studioBanner ? 600 : 0}/>
					<p class="votes">
						{#if results[opt.value]}
							{#if results[opt.value] == 1}
								1 vote
							{:else}
								{results[opt.value]} votes
							{/if}
						{:else}
							0 votes
						{/if}
					</p>
				{/if}
			</div>
		{/each}
	</div>
</div>
