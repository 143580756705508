<script>

	import { onMount, getContext, tick, createEventDispatcher } from 'svelte';
	import { fade, slide } from 'svelte/transition';
	import fadeScale from 'svelte-transitions-fade-scale';

	// import { draggable } from '@neodrag/svelte';
	import { v4 as uuid } from 'uuid';

	import Button from "../../../../ui/Button.svelte";
	import Dialog from "../../../../ui/Dialog.svelte";
	import Field from '../../../../ui/Field.svelte';

	import { event, session } from "../../../../lib/stores.js";
	import { postServerData } from '../../../../lib/prelude.js';
	import { autoblur } from '../../../../lib/autoblur.js';

	const dispatch = createEventDispatcher();

	const banner = getContext('banner');
	const banners = getContext('banners');
	const highlight = getContext('highlight');

	export let item;
	export let dragDisabled = false;
	// export let offsets = {};
	// export let heights = [];
	// export let i;
	// export let dragging = null;
	export let editing = null;

	let over = false;
	let deleteItemDialog = null;

	// let elem;
	// let transform = { x: 0, y: 0 };
	// let dragStartIdx;

	let bannerText;

	onMount(() => {
		if (item.edit) editing = item.id;
	});

	function toggleStatus(e) {
		e.stopPropagation();
		if ($banner == item.id) {
			$banner = null;
		} else {
			$highlight = null;
			$banner = item.id;
		}
	}

	function openDeleteItemDialog() {
		deleteItemDialog = {
			text: "Are you sure you want to delete this banner?",
			actions: [
				{
					label: "Confirm",
					dispatch: "confirm"
				},
				{
					label: "Cancel",
					ghost: true,
					dispatch: "escape"
				}
			]
		};
	}

	async function deleteItem() {
		if ($banner == item.id) {
			$banner = null;
		}
		let filtered = $banners.filter(a => item.id != a.ref);
		const json = JSON.stringify(filtered);
		await postServerData('virtual/studio/banners', {
			json: json,
			sessionRef: $session.ref
		});
		deleteItemDialog = null;
	}

	// function dragStart() {		
	// 	dragging = item.ref;
	// 	over = false;
	// 	dragStartIdx = i;
	// }

	// function drag({ offsetX, offsetY }) {
	// 	dispatch('consider', {
	// 		ref: item.ref,
	// 		i: i,
	// 		y: offsetY,
	// 		s: dragStartIdx
	// 	});
	// }

	// function dragEnd() {		
	// 	dragging = null;
	// 	for (const [k,v] of Object.entries(offsets)) {
	// 		offsets[k] = 0;
	// 	}
	// 	dispatch('finalise');
	// }

	// function setHeight() {
	// 	if (elem) {
	// 		heights[i] = elem.offsetHeight;
	// 	}
	// }

	// $: setHeight(i);

	// function startDrag(e) {
	// 	e.preventDefault();
	// 	dragDisabled = false;
	// }

	// function handleKeyDown(e) {
	// 	if ((e.key === "Enter" || e.key === " ") && dragDisabled) dragDisabled = false;
	// }

	function setBannerText() {
		bannerText = item.text;
	}

	async function setFocus() {
		if (editing == item.id) {
			await tick();
			document.getElementById('banner-' + item.id).focus();
		}
	}

	function handleKeydown(event){
		if (event.key === 'Escape') {
			if (editing && (editing == item.id)) {
				editing = null;
				dispatch('refresh');
				setBannerText();
			}
		}
	}

	async function saveEdits() {
		if (item.id == 'new') {
			$banners.push({
				ref: uuid(),
				text: bannerText
			});
		} else {
			for (const b of $banners) {
				if (b.ref == item.id) {
					b.text = bannerText;
				}
			}
		}
		const json = JSON.stringify($banners);
		postServerData('virtual/studio/banners', {
			json: json,
			sessionRef: $session.ref
		});
		editing = null;
		dispatch('refresh');
	}

	$: if (item) {
		setBannerText();
	}

	$: if (editing) {
		setFocus();
	}

</script>

<style>
	.item {
		position: relative;
/*		width: 70%;*/
		background: var(--blend-05);
		border-radius: 4px;
		overflow: hidden;
		display: flex;
		box-sizing: border-box;
		padding: 1rem;
		transform: width 0.3s ease;
	}

	:global(div.dragging) .item {
		box-shadow: 0 2px 12px -6px var(--shadow);
	}

	/*:global(.list.p0) .item,
	:global(.list.p1) .item {
		width: 100%;
	}

	:global(.list.p2) .item {
		width: 90%;
	}

	:global(.list.p3) .item {
		width: 80%;
	}*/

	.item.over {
		background: var(--blend-20);
	}

	.item.onstage,
	.item.over.onstage {
		background: var(--accentColor);
	}

	.text {
		position: relative;
		flex: 0 1 auto;
/*		min-height: 6rem;*/
		max-height: 9rem;
	}

	.text > p {
		margin: 0;
		color: var(--blend-80);
		line-height: 1.2;
		font-size: 0.8rem;
		white-space: pre-wrap;
	}

	.onstage .text > p {
		color: var(--panelColor);
	}

	:global(div:not(.dragging)) .item.over .text > p {
		opacity: 0.6;
/*		filter: blur(1px);*/
	}

	.control {
		position: absolute;
		z-index: 70;
		top: 50%;
		left: 50%;
		max-width: 100%;
		width: max-content;
		box-sizing: border-box;
		padding: 0.4rem;
		transform: translate(-50%,-50%);
	}

	.control :global(button.mini),
	.control :global(button.mini:hover),
	.control :global(button.mini:active),
	.control :global(button.mini:focus) {
		background: var(--panelColor);
		border-color: var(--panelColor);
		color: var(--textColor);
		padding-top: 0.4rem;
		padding-bottom: 0.4rem;
		box-shadow: 0 2px 10px var(--shadow);
		min-width: 7rem;
	}

	.control :global(button.mini:hover),
	.control :global(button.mini:active),
	.control :global(button.mini:focus) {
		background: var(--blend-10);
		border-color: var(--blend-10);
	}

	.actions {
		position: absolute;
		z-index: 70;
		top: 0.4rem;
		right: 0.4rem;
		display: flex;
		gap: 0.2rem;		
	}
	.actions button {
		position: relative;
		width: 1.4rem;
		height: 1.4rem;
		padding: 0;
		border: 0;
		background: var(--panelColor);
		color: var(--textColor);
		border-radius: 3px;
		cursor: pointer;
		box-shadow: 0 2px 4px -1px var(--shadow);
	}
	.actions button:hover,
	.actions button:active,
	.actions button:focus {
		background: var(--blend-10);
	}
	.actions button svg {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 1.2rem;
		height: 1.2rem;
		transform: translate(-50%, -50%);
	}
	/*.handle {
		background: var(--accentColor);
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1rem;
	}*/
	:global(div.dragging) .control,
	:global(div.dragging) .actions {
		opacity: 0;
	}
	.editing {
		padding: 0;
	}
	.editing :global(.field) {
		margin: 0;
		width: 100%;
	}
	.editing :global(.field .inputwrapper) {
		display: flex;
	}
	.editing :global(.field textarea) {
		padding: calc(1rem - 1px);
		border-color: var(--accentColor);
		line-height: 1.2;
		min-height: unset;
	}
	.save {
		padding: 0.6rem 0 0.4rem 0;
		display: flex;
		gap: 1rem;
		align-items: top;
		justify-content: space-between;
	}
	.save :global(.field) {
		flex: 0 1 auto;
	}
	.save .counter {
		font-size: 0.6875rem;
		color: var(--blend-40);
	}
	.save .counter.red {
		color: var(--red);
	}
</style>

<svelte:window on:keydown|stopPropagation={handleKeydown}/>

{#if editing && (editing == item.id)}
	<!-- svelte-ignore a11y-click-events-have-key-events -->
	<div on:click|stopPropagation>
		<div class="item editing">
			<Field
				ref="banner-{item.id}"
				bind:value={bannerText}
				component="TextArea"
				maxlength={150}
				placeholder="Type your banner"
			/>
		</div>
		<div class="save" in:slide|local={{ duration: 200 }}>
			<Button
				mini={true}
				grow={false}
				label="Save"
				on:click={saveEdits}
			/>
			<p class="counter" class:red={bannerText.length > 140}>{bannerText.length}/150</p>
		</div>
	</div>
{:else}
	<div
		class="item"
		class:onstage={$banner && ($banner == item.id)}
		class:over
		on:mouseenter={() => { over = true }}
		on:mouseleave={() => { over = false; dragDisabled = false; }}
		on:focus={() => { over = true }}
		on:blur={() => { over = false; dragDisabled = false; }}
	>
		<div class="text">
			<p>{item.text}</p>
		</div>
		{#if over}
			<div class="control" transition:fade|local={{ duration: 100 }}>
				<Button
					mini={true}
					customClass={($banner && ($banner == item.id)) ? 'onstage' : ''}
					label={($banner && ($banner == item.id)) ? 'Remove' : 'Add to stage'}
					on:mousedown={() => {dragDisabled = true}}
					on:mouseup={() => {dragDisabled = false}}
					on:touchstart={() => {dragDisabled = true}}
					on:touchend={() => {dragDisabled = false}}
					on:click={toggleStatus}
				/>
			</div>
			<div class="actions" transition:fade|local={{ duration: 100 }}>
				<button
					type="button"
					use:autoblur
					on:mousedown={() => {dragDisabled = true}}
					on:mouseup={() => {dragDisabled = false}}
					on:touchstart={() => {dragDisabled = true}}
					on:touchend={() => {dragDisabled = false}}
					on:click|stopPropagation={openDeleteItemDialog}
				>
					<svg viewBox="0 0 80 80">
						<path d="M48.01 57.55c-1.1 0-2-.9-2-2v-20.28c0-1.1.9-2 2-2s2 .9 2 2v20.28c0 1.11-.89 2-2 2zM40.01 57.55c-1.1 0-2-.9-2-2v-20.28c0-1.1.9-2 2-2s2 .9 2 2v20.28c0 1.11-.89 2-2 2zM32.01 57.55c-1.1 0-2-.9-2-2v-20.28c0-1.1.9-2 2-2s2 .9 2 2v20.28c0 1.11-.89 2-2 2zM62 21.27c0-2.21-1.79-4-4-4h-9.94c0-2.21-1.79-4-4-4h-8c-2.21 0-4 1.79-4 4h-10.06c-2.21 0-4 1.79-4 4v7.99h4.01v32.51c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4v-32.51h3.99v-7.99zm-8 38.51c0 1.1-.9 2-2 2h-24c-1.1 0-2-.9-2-2v-30.52h28v30.52zm4-34.52h-36v-1.96c0-1.1.9-2 2-2h32c1.1 0 2 .9 2 2v1.96z"/>
					</svg>
				</button>
				<button
					type="button"
					use:autoblur
					on:mousedown={() => {dragDisabled = true}}
					on:mouseup={() => {dragDisabled = false}}
					on:touchstart={() => {dragDisabled = true}}
					on:touchend={() => {dragDisabled = false}}
					on:click|stopPropagation={() => { editing = item.id; over = false; }}
				>
					<svg viewBox="0 0 80 80">
						<path d="M31.96 59.32l-12.66 3.33c-.55.15-1.14-.01-1.54-.42-.4-.4-.56-.99-.42-1.54l3.34-12.65 11.28 11.28zM49.05 19.64c3.12-3.12 8.19-3.12 11.31 0s3.12 8.19 0 11.31l-11.31-11.31zM57.48 33.84l-11.31-11.32-22.63 22.63 4.41 4.4 19.81-19.81 2.45 2.45-19.81 19.81 4.46 4.46z"/>
					</svg>
				</button>
			</div>
			<!-- <div
				tabindex={dragDisabled? 0 : -1} 
				aria-label="drag-handle"
				class="handle" 
				style={dragDisabled ? 'cursor: grab' : 'cursor: grabbing'}
				on:mousedown={startDrag} 
				on:touchstart={startDrag}
				on:keydown={handleKeyDown}
			/> -->
		{/if}
	</div>
{/if}

{#if deleteItemDialog}
	<Dialog
		{...deleteItemDialog}
		on:escape={() => { deleteItemDialog = null}}
		on:confirm={deleteItem}
	/>
{/if}