<script>

	import { onMount, getContext, tick } from 'svelte';
	import { fade } from "svelte/transition";
	import fadeScale from 'svelte-transitions-fade-scale';

	import Badge from "./Banner/Badge.svelte";
	import Highlight from "./Banner/Highlight.svelte";

	import { event, room, session, bigError } from "../../../lib/stores.js";
	import { getServerData } from '../../../lib/prelude.js';
	import { autoblur } from '../../../lib/autoblur.js';

	const banner = getContext('banner');
	const banners = getContext('banners');
	const highlight = getContext('highlight');
	const host = getContext('host');

	export let bannerHeight = 0;
	export let contentKey = null;

	let text = null;
	let fadeDuration = 0;

	let textElement;
	let textHeight;
	// let containerHeight;

	let font;
	let textColor;
	let bgColor;
	let bgOpacity;
	let fontWeight = 700;

	let fs = 0.04;

	let pollResults = null;
	let mounted = false;
	let badgeAspectRatio = 0;
	let over = false;

	onMount(async () => {
		if ($highlight) {
			fs = 0.025;
			if ($highlight.t == 'poll') {
				await getPollResults();
			}
		} else {
			setText();
		}
		mounted = true;
		checkOverflow();
	});

	function setText() {
		let bnr = null;
		if ($banner) {
			for (const b of $banners) {
				if (b.ref == $banner) {
					bnr = b.text;
					break;
				}
			}
		}
		text = bnr;
		if (mounted) {
			fs = 0.04;
			checkOverflow();
		}
	}

	async function getPollResults() {
		const pollData = await getServerData('virtual/polls', {
			poll: $highlight.r
		});
		if (!$bigError) {
			pollResults = pollData.results;
		}
	}

	async function checkOverflow(iteration) {
		if (iteration == undefined) {
			iteration = 0;
		}
		if (mounted) {
			await tick();
			if ((!bannerHeight || !textHeight) && (iteration < 100)) {				
				setTimeout(() => {
					iteration++;
					checkOverflow(iteration);
				}, 10);
			}
			if (textHeight > bannerHeight) {
				fs = fs - 0.0005;
				if (fs > 0.005) {
					setTimeout(() => {
						checkOverflow();
					}, 10);
				}
			}
		}
	}

	function setStyles() {
		if ($event.setup.virtual && $event.setup.virtual.studio && $event.setup.virtual.studio.banners) {
			font = $event.setup.virtual.studio.banners.font;
			textColor = $event.theme.colors[$event.setup.virtual.studio.banners.textColor];
			bgColor = $event.theme.colors[$event.setup.virtual.studio.banners.bgColor];
			bgOpacity = parseInt($event.setup.virtual.studio.banners.bgOpacity) / 100;
			badgeAspectRatio = ($event.setup.virtual.studio.banners.badge && $event.setup.virtual.studio.banners.badge.aspectRatio) ? $event.setup.virtual.studio.banners.badge.aspectRatio : 0;
			if ($event.theme && $event.theme.fonts && $event.theme.fonts.headings && $event.theme.fonts.headings.name && (font == $event.theme.fonts.headings.name) && $event.theme.fonts.headings.weight) {
				fontWeight = $event.theme.fonts.headings.weight;
			}
		} else {
			font = 'Inter';
			textColor = 'var(--textColor)';
			bgColor = 'var(--panelColor)';
			bgOpacity = 0.9;
			badgeAspectRatio = 0;
		}
	}

	// $: if (fs) {
	// 	checkOverflow();
	// }

	$: if ($event) {
		setStyles();
	}

	$: if ($banners) {
		setText();
	}

</script>

<style>
	.banner {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 3;
		padding: calc(var(--sw) * 0.03) calc(var(--sw) * 0.04);
		box-sizing: border-box;
		color: var(--bannerTextColor);
		font-size: calc(var(--sw) * var(--fs));
		overflow: hidden;
		line-height: 1.2;
		font-family: var(--bannerFont);
	}
	.banner.badged {
		padding-left: clamp(calc((var(--sw) * 0.06) + (var(--sw) * 0.07)), calc((var(--sw) * 0.06 / (var(--badgeAspectRatio) / 100)) + (var(--sw) * 0.07)), calc((var(--sw) * 0.15) + (var(--sw) * 0.07)));
/*		padding-left: calc(var(--sw) * 0.15);*/
/*		padding-left: calc(var(--sw) * 0.1 * (var(--badgeAspectRatio)) / 100);*/
/*		padding-left: calc(var(--sw) * 0.205);*/
	}
	.banner:before {
		content: '';
		position: absolute;
		inset: calc(var(--sw) * 0.018);
		border-radius: calc(var(--sw) * 0.005);
		background-color: var(--bannerBgColor);
		opacity: var(--bannerBgOpacity);
		z-index: 0;
		box-shadow: 0 0 calc(var(--sw) * 0.01) rgba(0, 0, 0, 0.3);
/*		border: calc(var(--sw) * 0.001) solid var(--bannerTextColor);*/
		transition: background-color 0.4s ease, opacity 0.4s ease;
	}
	.inner {
		position: relative;
		z-index: 2;
		max-height: calc(var(--sw) * 0.15);
	}
	.banner :global(p) {
		margin: 0;
		color: var(--bannerTextColor);
		transition: color 0.4s ease;
		white-space: pre-wrap;
		font-size: inherit;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	p {
		font-weight: var(--bannerFontWeight);
		min-height: calc(var(--sw) * 0.065);
	}

	.actions {
		position: absolute;
		z-index: 70;
		top: calc((var(--sw) * 0.018) + 0.4rem);
		right: calc((var(--sw) * 0.018) + 0.4rem);
		display: flex;
		gap: 0.2rem;		
	}
	.actions button {
		position: relative;
		width: 1.4rem;
		height: 1.4rem;
		padding: 0;
		border: 0;
		background: var(--panelColor);
		color: var(--textColor);
		border-radius: 3px;
		cursor: pointer;
		box-shadow: 0 2px 4px -1px var(--shadow);
	}
	.actions button:hover,
	.actions button:active,
	.actions button:focus {
		background: var(--blend-10);
	}
	.actions button svg {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 1.2rem;
		height: 1.2rem;
		transform: translate(-50%, -50%);
	}
</style>

{#if (contentKey != null) && mounted}
	<div
		class="banner"
		class:badged={$banner && $event.setup.virtual && $event.setup.virtual.studio && $event.setup.virtual.studio.banners && $event.setup.virtual.studio.banners.badge}
		in:fadeScale={{ duration: 200, delay: 200, baseScale: 0.95 }}
		style="--bannerFont:{font};--bannerFontWeight:{fontWeight};--bannerTextColor:{textColor};--bannerBgColor:{bgColor};--bannerBgOpacity:{bgOpacity};--badgeAspectRatio:{badgeAspectRatio};--fs:{fs};"
		on:mouseenter={() => { over = true }}
		on:mouseleave={() => { over = false }}
	>
		<div class="inner" bind:clientHeight={bannerHeight}>
			<div
				class="text"
				bind:this={textElement}
				bind:clientHeight={textHeight}
			>
				{#if $banner && text}
					<p in:fade={{ duration: 200, delay: 400 }}>{text}</p>
				{:else if $highlight}
					<Highlight {pollResults} on:ready={checkOverflow}/>
				{/if}
			</div>
		</div>
		{#if $banner && $event.setup.virtual && $event.setup.virtual.studio && $event.setup.virtual.studio.banners && $event.setup.virtual.studio.banners.badge}
			{#key $event.setup.virtual.studio.banners.badge.filename}
				<Badge/>
			{/key}
		{/if}

		{#if over && host}
			<div class="actions" transition:fade|local={{ duration: 100 }}>
				<button
					type="button"
					use:autoblur
					on:click|stopPropagation={() => { $banner = null; $highlight = null; }}
				>
					<svg viewBox="0 0 20 20">
						<path d="M10 3.5c3.58 0 6.5 2.92 6.5 6.5s-2.92 6.5-6.5 6.5-6.5-2.92-6.5-6.5 2.92-6.5 6.5-6.5m0-1c-4.14 0-7.5 3.36-7.5 7.5s3.36 7.5 7.5 7.5 7.5-3.36 7.5-7.5-3.36-7.5-7.5-7.5zM13.99 6.86l-3.14 3.14 3.14 3.14-.86.86-3.14-3.14-3.15 3.14-.84-.84 3.14-3.14-3.14-3.15.86-.86 3.14 3.14 3.14-3.14.85.85z"/>
					</svg>
				</button>
			</div>
		{/if}
	</div>
{/if}