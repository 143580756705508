<script>

	import { fade, fly } from "svelte/transition";
	// import fadeScale from 'svelte-transitions-fade-scale';

	import Player from './ui/Player.svelte';
	import Brand from './ui/Brand.svelte';
	import LobbyImage from './lobby/LobbyImage.svelte';

	import { event, lobby } from './lib/stores.js';
	import { randomIntFromInterval } from './lib/utils.js';

	let i = 0
	let card = $lobby[i];

	let fwd = true;

	let muted = true;

	let cw;

	function prevCard() {
		if (i == 0) {
			i = $lobby.length - 1;
		} else {
			i = i - 1;
		}
		fwd = false;
		card = $lobby[i];
	}

	function nextCard() {
		if (i == ($lobby.length - 1)) {
			i = 0;
		} else {
			i = i + 1;
		}
		fwd = true;
		card = $lobby[i];
	}

	// async function hammer() {

	// 	let attendance = await $syncClient.map(syncChannels.attendance);

	// 	for (let i = 0; i < 1000; i++) { 
	// 		getMapData(attendance,i);
	// 	}

	// }

	// function getMapData(attendance,i,s) {
	// 	if (!s) s = '';
	// 	attendance.getItems({ pageSize: 1 })
	// 		.then((r) => { // console.log(s,i) })
	// 		.catch((error) => {
	// 			if (error.message.match('Maximum attempt')) {
	// 				// back off and try again later...
	// 				const smear = 1000 * randomIntFromInterval(5,10);
	// 				console.error('LIMIT', i, '//', error.name, '//', error.message, '//', smear);
	// 				setTimeout(() => {
	// 					getMapData(attendance,i,'SMEARED');
	// 				}, smear);
	// 			} else {
	// 				console.error(error);
	// 			}
	// 		});
	// }

	$: if ($lobby) {
		card = $lobby[0];
	}

</script>

<style>

	.lobby {
		grid-area: c;
		position: relative;
		min-height: 400px;
	}
	@media (max-width: 675px) {
		.lobby {
			min-height: calc((100vw * 0.56) + 2rem);
		}
	}
	.inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
	}
	.cards {
		flex: 0 1 auto;
		position: relative;
		aspect-ratio: 16/9;
		width: 100%;
		max-height: 100%;
	}

	.cards > div {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		aspect-ratio: 16/9;
		max-width: 100%;
		max-height: 100%;
		height: 100%;
	}

	.card {
		position: absolute;
		inset: 0;
		border-radius: 4px;
		box-shadow: 0 2px 10px var(--shadow);
    	overflow: hidden;
	}

	.card:before {
		content: '';
		position: absolute;
		inset: 0;
  		border-radius: 4px;
		background: #000;
		opacity: 0.4;
	}
	.card > img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
  		border-radius: 4px;
  		pointer-events: none;
	}

	.arrows {
		flex: 0 0 auto;
		width: var(--cw);
		text-align: right;
	}

	.arrows > div {
		display: inline-flex;
		gap: 0.6rem;
	}

	.arrows button {
		position: relative;
		border: 1px solid var(--captionColor);
		background: transparent;
		border-radius: 100px;
		color: var(--captionColor);
		font-size: 0.8rem;
		padding: 0;
		width: 1.5rem;
		height: 1.5rem;
		transition: transform 0.2s ease;
		cursor: pointer;
	}
	.arrows button svg {
		position: absolute;
		inset: 0;
	}
	.arrows button.forward svg {
		transform: rotate(180deg);
	}
	.arrows button:hover {
		transform: scale(1.1);
	}

	.placeholder {
		position: absolute;
		inset: 0;
		background: var(--panelColor);
  		border-radius: 4px;
  		display: flex;
  		justify-content: center;
  		align-items: center;
  		overflow: hidden;
  		padding: 20%;
  		pointer-events: none;
	}

	.placeholder :global(.brand) {
		width: 100%;
		height: 0;
		padding-top: var(--aspectRatio);
		position: relative;
		margin: 0;
	}

	.placeholder :global(.brand img) {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		max-width: unset !important;
		max-height: unset !important;
	}

	.placeholder :global(.brand span) {
		font-size: 2rem;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}

	.placeholder :global(.brand img.svg) {
		height: auto !important;
	}

	.placeholder :global(.brand:after) {
		max-width: unset;
	}

</style>

<svelte:head>
    {#each $lobby as card}
    	{#if card.hash}
	      <link rel="preload" as="image" href="https://grids.attendzen.io/{$event.accountRef}/lobby-{card.hash}.jpg" />
    	{/if}
    {/each}
</svelte:head>

<div class="lobby" in:fade={{ delay: 200, duration: 300 }}>
	<div class="inner" style="--cw:{cw}px">
		<div class="cards">
			<div bind:clientWidth={cw}>
				{#if card}
					{#key card.ref}
						<div class="card" in:fly={{ delay: 100, duration: 600, x: (fwd ? 100 : -100) }} out:fly|local={{ duration: 300, x: (fwd ? -50 : 50) }}>
							{#if card.hash}
								<img src="https://grids.attendzen.io/{$event.accountRef}/lobby-{card.hash}.jpg" alt="{card.text}"/>
							{:else if card.image}
								<LobbyImage {card}/>
							{:else if card.playback}
								<Player
									provider={card.provider}
									playback={card.playback}
									token={card.token}
									poster={card.poster}
									autoplay={card.autoplay}
									bind:muted
								/>
							{/if}
						</div>
					{/key}
				{:else}
					<div class="card" in:fly={{ delay: 100, duration: 600, x: 100 }}>
						<div class="placeholder">
							<Brand/>
						</div>
					</div>
				{/if}
			</div>
		</div>
		{#if $lobby.length > 1}
			<div class="arrows">
				<div>
					<button type="button" class="back" on:click={prevCard}>
						<svg viewBox="0 0 27 27"><title>Back</title><path d="M20.41 12.5h-11.41l4.02-4.02c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0l-5.73 5.73c-.39.39-.39 1.02 0 1.41l5.73 5.73c.2.2.45.29.71.29s.51-.1.71-.29c.39-.39.39-1.02 0-1.41l-4.03-4.03h11.41c.55 0 1-.45 1-1s-.44-1-1-1z"/></svg>
					</button>
					<button type="button" class="forward" on:click={nextCard}>
						<svg viewBox="0 0 27 27"><title>Back</title><path d="M20.41 12.5h-11.41l4.02-4.02c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0l-5.73 5.73c-.39.39-.39 1.02 0 1.41l5.73 5.73c.2.2.45.29.71.29s.51-.1.71-.29c.39-.39.39-1.02 0-1.41l-4.03-4.03h11.41c.55 0 1-.45 1-1s-.44-1-1-1z"/></svg>
					</button>
				</div>
			</div>
		{/if}
	</div>
</div>
