import { get } from 'svelte/store';
import { event, displayInEventTz } from './stores.js';

import moment from 'moment-timezone';

//import momentRange from 'moment-range';
//const range = momentRange.extendMoment(moment);

export const now = () => {
	// todo: accommodate utcDiff
	return moment().utc();
};

export const getEpoch = (dt) => {
	if (!dt) dt = now();
	return dt.unix();
};

export const showDate = (dt, fmt) => {
	if (!dt) dt = now();
	if (!fmt) fmt = 'DD MMM YY [at] HH:mm';
	const useEventTz = get(displayInEventTz);
	if (useEventTz) {
		const e = get(event);
		const tz = e.setup.timeZone;
		return moment.utc(dt).tz(tz).format(fmt);
	} else {
		return moment.utc(dt).local().format(fmt);
	}
};

export const showEpoch = (e, fmt) => {
	const dt = moment.unix(e).utc();
	return showDate(dt, fmt);
};

export const fromUTC = (dt,tz) => {
	if (tz) {
		return moment.utc(dt).tz(tz);
	} else {
		return moment.utc(dt).local();
	}
};

export const toUTC = (dt,tz) => {
	if (tz) {
		return moment.tz(dt,tz).utc();
	} else {
		return moment(dt).local().utc();
	}
};

export const iso = (dt,tz) => {
	if (tz) {
		return moment.utc(dt).tz(tz).toISOString();
	} else {
		return moment.utc(dt).local().toISOString();
	}
};

export const isPast = (m) => {
	let now = now();
	return m.isBefore(now) ? true : false;
};

export const isToday = (m,e) => {
	let now = e ? moment.unix(e).utc() : moment().utc();
	const useEventTz = get(displayInEventTz);
	if (useEventTz) {
		const e = get(event);
		const tz = e.setup.timeZone;
		return m.tz(tz).isSame(now.tz(tz), "day") ? true : false;
	} else {
		return m.local().isSame(now.local(), "day") ? true : false;
	}
};

export const isSameDay = (m1,m2) => {
	const useEventTz = get(displayInEventTz);
	if (useEventTz) {
		const e = get(event);
		const tz = e.setup.timeZone;
		return m1.tz(tz).isSame(m2.tz(tz), "day") ? true : false;
	} else {
		return m1.local().isSame(m2.local(), "day") ? true : false;
	}
};